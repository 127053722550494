import { axiosClient } from '../utils/axiosClient';

const propertiesEndpoint = 'v1/clients/properties';

export const getProperties = (filters = {}) => {
  const { page = 0, limit = 10, createdFromDate, createdToDate, propertyStatus } = filters;

  const params = {
    from_date: createdFromDate,
    limit,
    offset: limit * page,
    property_status: propertyStatus,
    to_date: createdToDate
  };

  const query = Object.entries(params)
    // eslint-disable-next-line no-unused-vars
    .filter(([_, value]) => value !== undefined && value !== '' && value !== null)
    .map(([key, value]) => `${key}=${value}`)
    .join('&');

  const queryString = query ? `?${query}` : '';

  return axiosClient.get(propertiesEndpoint + queryString).then((resp) => resp?.data);
};

export const getPropertyDetails = (propertyId) => {
  return axiosClient.get(propertiesEndpoint + propertyId).then((resp) => resp?.data);
};
