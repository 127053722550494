import { createSlice } from '@reduxjs/toolkit';
import {
  addClient,
  getClientChatMessages,
  getClientChats,
  getClientDetails,
  getClients,
  updateClient
} from 'api/client';
import { toast } from 'react-toastify';

export const clientFilterInitialValues = {
  country: '',
  createdFromDate: '',
  createdToDate: '',
  limit: '',
  page: '',
  propertiesEndCount: '',
  propertiesStartCount: '',
  status: ''
};

const clientSlice = createSlice({
  initialState: {
    currentTablePage: 0,
    filters: clientFilterInitialValues,
    isLoading: false,
    rowPerPage: 10
  },
  name: 'client',
  reducers: {
    setCurrentTablePage(state, action) {
      state.currentTablePage = action.payload;
    },
    setFilters(state, action) {
      state.filters = action.payload;
    },
    setIsLoading(state, action) {
      state.isLoading = action.payload;
    },
    setRowPerPage(state, action) {
      state.rowPerPage = action.payload;
    }
  }
});

export const clientActions = clientSlice.actions;

export const handleGetClients = (filters) => async (dispatch) => {
  try {
    dispatch(clientActions.setIsLoading(true));
    const data = await getClients(filters);
    return data;
  } catch (error) {
    dispatch(clientActions.setIsLoading(false));
    let errorMessage = error?.message;

    if (error?.response) {
      errorMessage = error?.response?.data?.message || error?.response?.data?.error;

      if (error?.response?.status === 502) {
        errorMessage = 'The server encountered a problem';
      }
    } else if (error?.request) {
      errorMessage = 'Request was made but no response was received';
    }
    console.log(errorMessage);
  } finally {
    dispatch(clientActions.setIsLoading(false));
  }
};

export const handleGetClientDetails = (id) => async (dispatch) => {
  try {
    dispatch(clientActions.setIsLoading(true));
    const data = await getClientDetails(id);
    return data;
  } catch (error) {
    dispatch(clientActions.setIsLoading(false));
    let errorMessage = error?.message;

    if (error?.response) {
      errorMessage = error?.response?.data?.message || error?.response?.data?.error;

      if (error?.response?.status === 502) {
        errorMessage = 'The server encountered a problem';
      }
    } else if (error?.request) {
      errorMessage = 'Request was made but no response was received';
    }
    console.log(errorMessage);
  } finally {
    dispatch(clientActions.setIsLoading(false));
  }
};

export const handleGetClientChats = (id) => async (dispatch) => {
  try {
    dispatch(clientActions.setIsLoading(true));
    const data = await getClientChats(id);
    return data;
  } catch (error) {
    dispatch(clientActions.setIsLoading(false));
    let errorMessage = error?.message;

    if (error?.response) {
      errorMessage = error?.response?.data?.message || error?.response?.data?.error;

      if (error?.response?.status === 502) {
        errorMessage = 'The server encountered a problem';
      }
    } else if (error?.request) {
      errorMessage = 'Request was made but no response was received';
    }
    console.log(errorMessage);
  } finally {
    dispatch(clientActions.setIsLoading(false));
  }
};

export const handleGetClientChatMessages = (clientId, chatId) => async (dispatch) => {
  try {
    dispatch(clientActions.setIsLoading(true));
    const data = await getClientChatMessages(clientId, chatId);
    return data;
  } catch (error) {
    dispatch(clientActions.setIsLoading(false));
    let errorMessage = error?.message;

    if (error?.response) {
      errorMessage = error?.response?.data?.message || error?.response?.data?.error;

      if (error?.response?.status === 502) {
        errorMessage = 'The server encountered a problem';
      }
    } else if (error?.request) {
      errorMessage = 'Request was made but no response was received';
    }
    console.log(errorMessage);
  } finally {
    dispatch(clientActions.setIsLoading(false));
  }
};

export const handleAddClient = (params) => async (dispatch) => {
  try {
    dispatch(clientActions.setIsLoading(true));
    const data = await addClient(params);
    if (data?.message) {
      toast.success(data?.message);
    }
    return data;
  } catch (error) {
    dispatch(clientActions.setIsLoading(false));
    let errorMessage = error?.message;

    if (error?.response) {
      errorMessage = error?.response?.data?.message || error?.response?.data?.error;

      if (error?.response?.status === 502) {
        errorMessage = 'The server encountered a problem';
      }
    } else if (error?.request) {
      errorMessage = 'Request was made but no response was received';
    }
    toast.error(errorMessage || 'Something went wrong');
  } finally {
    dispatch(clientActions.setIsLoading(false));
  }
};

export const handleUpdateClient = (clientId, params) => async (dispatch) => {
  try {
    dispatch(clientActions.setIsLoading(true));
    const data = await updateClient(clientId, params);
    if (data?.message) {
      toast.success(data?.message);
    }
    return data;
  } catch (error) {
    dispatch(clientActions.setIsLoading(false));

    for (const values in error?.response?.data) {
      toast.error(
        values + ': ' + error?.response?.data?.[values]?.[0] || 'Oops ! some error occurred!'
      );
    }
  } finally {
    dispatch(clientActions.setIsLoading(false));
  }
};

export default clientSlice;
