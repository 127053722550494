'use client';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { Avatar, Box, Card, CardContent, Divider, Stack, Typography } from '@mui/material';
import { userSignout } from 'store/authSlice';
import { SidebarItem } from 'utils/enums';
import ImagesLinks from 'utils/ImagesLinks';
import styles from './Sidebar.styles';

export default function Sidebar() {
  const classes = styles();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { pathname } = useLocation();

  const { userName, userId, userAvatar } = useSelector((state) => state?.auth);

  return (
    <Box className={classes.sidebar}>
      <Card variant="text">
        <CardContent className={classes.profileCard}>
          <Avatar className={classes.avatar} sx={{ height: 60, width: 60 }} src={userAvatar} />
          <Typography className={classes.userName} sx={{ marginTop: '12px' }}>
            {userName || ''}
          </Typography>
          {userId && <Typography className={classes.userId}>ID: {userId || ''}</Typography>}
        </CardContent>
      </Card>

      <Divider sx={{ margin: '0 1.5rem 1rem 1.5rem', paddingTop: '16px' }} />

      <Stack>
        {SidebarItem.map((item, index) => (
          <Box
            sx={{
              background: pathname.startsWith(`/${item?.name?.toLowerCase()?.replace(' ', '-')}`)
                ? '#E5EFF9'
                : '#fff'
            }}
            key={index}
            className={item.name === 'Logout' ? classes.logoutBtn : classes.sidebarItem}
            onClick={() => {
              if (item.name === 'Logout') {
                dispatch(userSignout());
              } else {
                item.redirectTo && navigate(item.redirectTo);
              }
            }}>
            {item.icon ? (
              <item.icon className={item.name === 'Logout' ? classes.logoutText : classes.userId} />
            ) : (
              <img src={ImagesLinks.sidebar.airaIcon} />
            )}

            <Typography className={item.name === 'Logout' ? classes.logoutText : classes.userId}>
              {item.name}
            </Typography>
          </Box>
        ))}
      </Stack>
    </Box>
  );
}
