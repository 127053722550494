import { createSlice } from '@reduxjs/toolkit';
import { getProperties, getPropertyDetails } from 'api/property';

export const propertyFilterInitialValues = {
  createdFromDate: '',
  createdToDate: '',
  limit: '',
  page: '',
  propertyStatus: ''
};

const propertySlice = createSlice({
  initialState: {
    currentTablePage: 0,
    filters: propertyFilterInitialValues,
    isLoading: false,
    rowPerPage: 10
  },
  name: 'property',
  reducers: {
    setCurrentTablePage(state, action) {
      state.currentTablePage = action.payload;
    },
    setFilters(state, action) {
      state.filters = action.payload;
    },
    setIsLoading(state, action) {
      state.isLoading = action.payload;
    },
    setRowPerPage(state, action) {
      state.rowPerPage = action.payload;
    }
  }
});

export const propertyActions = propertySlice.actions;

export const handleGetProperties = (params) => async (dispatch) => {
  try {
    dispatch(propertyActions.setIsLoading(true));
    const data = await getProperties(params);
    return data;
  } catch (error) {
    dispatch(propertyActions.setIsLoading(false));
    let errorMessage = error?.message;

    if (error?.response) {
      errorMessage = error?.response?.data?.message || error?.response?.data?.error;

      if (error?.response?.status === 502) {
        errorMessage = 'The server encountered a problem';
      }
    } else if (error?.request) {
      errorMessage = 'Request was made but no response was received';
    }
    console.log(errorMessage);
  } finally {
    dispatch(propertyActions.setIsLoading(false));
  }
};

export const handleGetPropertyDetails = (propertyId) => async (dispatch) => {
  try {
    dispatch(propertyActions.setIsLoading(true));
    const data = await getPropertyDetails(propertyId);
    return data;
  } catch (error) {
    dispatch(propertyActions.setIsLoading(false));
    let errorMessage = error?.message;

    if (error?.response) {
      errorMessage = error?.response?.data?.message || error?.response?.data?.error;

      if (error?.response?.status === 502) {
        errorMessage = 'The server encountered a problem';
      }
    } else if (error?.request) {
      errorMessage = 'Request was made but no response was received';
    }
    console.log(errorMessage);
  } finally {
    dispatch(propertyActions.setIsLoading(false));
  }
};

export default propertySlice;
