import { createSlice } from '@reduxjs/toolkit';
import { getAiraDashboardDetails } from 'api/aira';

export const airaDashboardFilterInitialValues = {
  createdFromDate: '',
  createdToDate: '',
  selectedDateFilter: 'LifeTime'
};

const airaSlice = createSlice({
  initialState: {
    filters: airaDashboardFilterInitialValues,
    isLoading: false
  },
  name: 'aira',
  reducers: {
    setFilters(state, action) {
      state.filters = action.payload;
    },
    setIsLoading(state, action) {
      state.isLoading = action.payload;
    }
  }
});

export const airaActions = airaSlice.actions;

export const handleGetAiraDashboardDetails = (filters) => async (dispatch) => {
  try {
    dispatch(airaActions.setIsLoading(true));
    const data = await getAiraDashboardDetails(filters);
    return data;
  } catch (error) {
    dispatch(airaActions.setIsLoading(false));
    let errorMessage = error?.message;

    if (error?.response) {
      errorMessage = error?.response?.data?.message || error?.response?.data?.error;

      if (error?.response?.status === 502) {
        errorMessage = 'The server encountered a problem';
      }
    } else if (error?.request) {
      errorMessage = 'Request was made but no response was received';
    }
    console.log(errorMessage);
  } finally {
    dispatch(airaActions.setIsLoading(false));
  }
};

export default airaSlice;
