import { axiosClient } from '../utils/axiosClient';

const profileDropdownValuesEndpoint = 'v1/client/profile/dropdown/values';
const countriesDropdownValuesEndpoint = 'v1/client/profile/country';
const statesDropdownValuesEndpoint = 'v1/client/profile/state?';
const citiesDropdownValuesEndpoint = 'v1/client/profile/city?';

export const getProfileDropdownValues = () => {
  return axiosClient.get(profileDropdownValuesEndpoint).then((resp) => resp?.data);
};

export const getCountriesDropDownValues = () =>
  axiosClient.get(countriesDropdownValuesEndpoint).then((resp) => resp?.data);

export const getStatesDropDownValues = (countryCode) =>
  axiosClient
    .get(statesDropdownValuesEndpoint + `country_code=${countryCode}`)
    .then((resp) => resp?.data);

export const getCitiesDropDownValues = (countryCode, stateCode) =>
  axiosClient
    .get(citiesDropdownValuesEndpoint + `country_code=${countryCode}&state_code=${stateCode}`)
    .then((resp) => resp?.data);
