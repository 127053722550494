import { makeStyles } from '@mui/styles';
import { COLORS } from 'utils/enums';

const sidebarStyles = makeStyles({
  avatar: {
    marginTop: '2rem'
  },
  logoutBtn: {
    '&:hover': {
      backgroundColor: COLORS.SecondaryBtn,
      cursor: 'pointer'
    },
    alignItems: 'center',
    borderBottomRightRadius: '8px',
    bottom: '12px',
    display: 'flex',
    gap: '1rem',
    paddingLeft: '1.5rem',
    paddingTop: '12px',
    position: 'absolute',
    width: '90%'
  },
  logoutText: {
    color: '#A03C42',
    fontFamily: 'Inter',
    fontSize: '14px',
    fontWeight: 400,
    paddingBottom: '10px'
  },
  profileCard: {
    alignItems: 'center',
    borderTopRightRadius: '8px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    padding: '0 !important'
  },
  sidebar: {
    background: '#fff',
    border: '1px solid rgba(10, 58, 103, 0.2)',
    borderBottom: '1px solid #D2D2D2',
    borderRadius: '0px 8px 8px 0px',
    borderRight: '1px solid #D2D2D2',
    borderTop: '1px solid #D2D2D2',
    maxHeight: '98vh',
    minWidth: '210px',
    overflowY: 'auto',
    position: 'sticky',
    textAlign: 'center',
    top: '0',
    width: '20%'
  },
  sidebarItem: {
    '&:hover': {
      backgroundColor: '#F5EDFF',
      cursor: 'pointer'
    },
    alignItems: 'center',
    display: 'flex',
    gap: '1rem',
    paddingBottom: '12px',
    paddingLeft: '1.5rem',
    paddingTop: '12px'
  },

  userId: {
    color: COLORS.TextSecondary,
    fontFamily: 'Inter',
    fontSize: '14px',
    fontWeight: 400
  },

  userName: {
    color: COLORS.TextPrimary,
    fontFamily: 'Inter',
    fontSize: '1rem',
    fontWeight: 500
  }
});

export default sidebarStyles;
