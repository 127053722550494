import { createSlice } from '@reduxjs/toolkit';
import {
  getCitiesDropDownValues,
  getCountriesDropDownValues,
  getProfileDropdownValues,
  getStatesDropDownValues
} from 'api/dropdown';

const dropdownSlice = createSlice({
  initialState: {
    countriesDropdownValues: [],
    profileDropdownValues: []
  },
  name: 'dropdown',
  reducers: {
    setCountriesDropdownValues(state, action) {
      state.countriesDropdownValues = action.payload;
    },
    setIsLoading(state, action) {
      state.isLoading = action.payload;
    },
    setProfileDropdownValues(state, action) {
      state.profileDropdownValues = action.payload;
    }
  }
});

export const dropdownActions = dropdownSlice.actions;

export const handleGetProfileDropdownValues = () => async () => {
  try {
    const data = await getProfileDropdownValues();
    return data;
  } catch (error) {
    let errorMessage = error?.message;

    if (error?.response) {
      errorMessage = error?.response?.data?.message || error?.response?.data?.error;

      if (error?.response?.status === 502) {
        errorMessage = 'The server encountered a problem';
      }
    } else if (error?.request) {
      errorMessage = 'Request was made but no response was received';
    }
    console.log(errorMessage);
  }
};

export const handleGetCountriesDropDownValues = () => async (dispatch) => {
  try {
    const data = await getCountriesDropDownValues();
    dispatch(dropdownActions.setCountriesDropdownValues(data?.[0]?.countries));

    return data;
  } catch (error) {
    let errorMessage = error?.message;

    if (error?.response) {
      errorMessage = error?.response?.data?.message || error?.response?.data?.error;

      if (error?.response?.status === 502) {
        errorMessage = 'The server encountered a problem';
      }
    } else if (error?.request) {
      errorMessage = 'Request was made but no response was received';
    }
    console.log(errorMessage);
  }
};

export const handleGetStatesDropDownValues = (countryCode) => async () => {
  try {
    const data = await getStatesDropDownValues(countryCode);
    return data;
  } catch (error) {
    let errorMessage = error?.message;

    if (error?.response) {
      errorMessage = error?.response?.data?.message || error?.response?.data?.error;

      if (error?.response?.status === 502) {
        errorMessage = 'The server encountered a problem';
      }
    } else if (error?.request) {
      errorMessage = 'Request was made but no response was received';
    }
    console.log(errorMessage);
  }
};

export const handleGetCitiesDropDownValues = (countryCode, stateCode) => async () => {
  try {
    const data = await getCitiesDropDownValues(countryCode, stateCode);
    return data;
  } catch (error) {
    let errorMessage = error?.message;

    if (error?.response) {
      errorMessage = error?.response?.data?.message || error?.response?.data?.error;

      if (error?.response?.status === 502) {
        errorMessage = 'The server encountered a problem';
      }
    } else if (error?.request) {
      errorMessage = 'Request was made but no response was received';
    }
    console.log(errorMessage);
  }
};

export default dropdownSlice;
