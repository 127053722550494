import { axiosClient } from '../utils/axiosClient';

export const getClients = (filters = {}) => {
  const {
    page = 0,
    limit = 10,
    createdFromDate,
    createdToDate,
    country,
    status,
    propertiesStartCount,
    propertiesEndCount
  } = filters;

  const params = {
    country,
    from_date: createdFromDate,
    limit,
    offset: limit * page,
    properties_end_count: propertiesEndCount,
    properties_start_count: propertiesStartCount,
    status,
    to_date: createdToDate
  };

  const query = Object.entries(params)
    // eslint-disable-next-line no-unused-vars
    .filter(([_, value]) => value !== undefined && value !== '' && value !== null)
    .map(([key, value]) => `${key}=${value}`)
    .join('&');

  const queryString = query ? `?${query}` : '';

  return axiosClient.get('v1/clients' + queryString).then((resp) => resp?.data);
};

export const getClientDetails = (clientId) => {
  return axiosClient.get(`v1/client/${clientId}/details`).then((resp) => resp?.data);
};

export const getClientChats = (clientId) => {
  return axiosClient.get(`v1/client/${clientId}/chats`).then((resp) => resp?.data);
};

export const getClientChatMessages = (clientId, chatId) => {
  return axiosClient
    .get(`v1/client/${clientId}/chat/${chatId}/messages`)
    .then((resp) => resp?.data);
};

export const addClient = (params) => {
  return axiosClient.post('/v1/client/add', params).then((resp) => resp?.data);
};

export const updateClient = (clientId, params) => {
  return axiosClient.patch(`v1/client/${clientId}/update`, params).then((resp) => resp?.data);
};
