import {
  IconBuildingSkyscraper,
  IconCheckbox,
  IconLayoutDashboard,
  IconLogout,
  IconMessageReport,
  IconUser,
  IconUserPlus
} from '@tabler/icons-react';
import moment from 'moment';

export const COLORS = {
  Background: '#FEFEFE',
  PrimaryBtn: '#600DB4',
  PrimaryPurple: '#600DB4',
  SecondaryBtn: '#F5EDFF',
  TertiaryBtn: '#7B0DB4',
  TextPrimary: '#474747',
  TextSecondary: '#7D7D7D',
  VeryLightPurple: '#F5EDFF',
  stroke: '#E3E3E3'
};

export const SidebarItem = [
  // { icon: IconLayoutDashboard, name: 'Dashboard', redirectTo: '#' },
  { icon: IconUser, name: 'Users', redirectTo: '/users' },
  // { icon: IconUserPlus, name: 'Realtors', redirectTo: '#' },
  // {
  //   icon: IconBuildingSkyscraper,
  //   name: 'Properties',
  //   redirectTo: '#'
  // },
  // {
  //   icon: IconCheckbox,
  //   name: 'Property Verification',
  //   redirectTo: '#'
  // },
  // {
  //   icon: IconMessageReport,
  //   name: 'Support Tickets',
  //   redirectTo: '#'
  // },
  { name: 'AIRA Dashboard', redirectTo: '/aira-dashboard' },
  { icon: IconLogout, name: 'Logout', redirectTo: '#' }
];

export const phoneRegExp =
  /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;

export const EMAIL_REGEX = /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;

export const STRING_SPACE_REGEX = /^[a-zA-Z\s]*$/g;

export function stringToColor(string) {
  let hash = 0;
  let i;

  for (i = 0; i < string?.length; i += 1) {
    hash = string?.charCodeAt(i) + ((hash << 5) - hash);
  }

  let color = '#';

  for (i = 0; i < 3; i += 1) {
    const value = (hash >> (i * 8)) & 0xff;
    color += `00${value?.toString(16)}`?.slice(-2);
  }

  return color;
}

export function stringAvatar(name) {
  const nameParts = name?.split(' ') || [];
  return {
    children: `${nameParts[0]?.[0] || ''}${nameParts[1]?.[0] || ''}`,
    sx: {
      bgcolor: stringToColor(name),
      fontSize: '14px'
    }
  };
}

export function getColor(name) {
  switch (name) {
    case 'Lunch / Holiday':
      return 'rgba(255, 185, 100, 1)';
    case 'Others':
      return 'rgba(185, 179, 218, 1)';
    case 'Existing To Branch':
      return 'rgba(109, 177, 224, 1)';
    case 'Idle':
      return 'rgba(242, 195, 217, 1)';
    default:
      return 'rgba(242, 195, 217, 1)';
  }
}

export function getCurrentFinancialQuarter() {
  const today = moment();
  const startOfYear = moment(today).startOf('year');

  let startQuarter = startOfYear;
  let endQuarter = startOfYear;

  // Determine the financial quarter
  const quarter = Math.floor((today.month() - startQuarter.month()) / 3) + 1;

  // Calculate start and end dates of the current financial quarter with the current year
  startQuarter.set('month', (quarter - 1) * 3);
  endQuarter = moment(startQuarter).endOf('quarter');

  return {
    end: endQuarter.format('YYYY-MM-DD'),
    start: startQuarter.format('YYYY-MM-DD')
  };
}

export const dateMappings = {
  Default: { fromDate: moment().format('YYYY-MM-DD'), toDate: '2014-01-01' },
  LifeTime: { fromDate: '2000-01-01', toDate: moment().format('YYYY-MM-DD') },
  MTD: {
    fromDate: moment().clone().startOf('month').format('YYYY-MM-DD'),
    toDate: moment().clone().endOf('month').format('YYYY-MM-DD')
  },
  QTD: {
    fromDate: getCurrentFinancialQuarter().start,
    toDate: getCurrentFinancialQuarter().end
  },
  Today: { fromDate: moment().format('YYYY-MM-DD'), toDate: moment().format('YYYY-MM-DD') },
  WTD: {
    fromDate: moment().clone().subtract(7, 'days').format('YYYY-MM-DD'),
    toDate: moment().clone().add(1, 'days').format('YYYY-MM-DD')
  },
  YTD: {
    fromDate: moment().clone().startOf('year').format('YYYY-MM-DD'),
    toDate: moment().clone().endOf('year').format('YYYY-MM-DD')
  },
  Yesterday: {
    fromDate: moment().subtract(1, 'days').format('YYYY-MM-DD'),
    toDate: moment().subtract(1, 'days').format('YYYY-MM-DD')
  }
};

export const dashboardFilterMenuItems = [
  { label: 'Today', value: 'Today' },
  { label: 'Yesterday', value: 'Yesterday' },
  { label: 'This Week', value: 'WTD' },
  { label: 'This Month', value: 'MTD' },
  { label: 'This Quarter', value: 'QTD' },
  { label: 'This Year', value: 'YTD' },
  { label: 'Custom', value: 'Custom' },
  { label: 'LifeTime', value: 'LifeTime' }
];

export function randomDarkColor() {
  var lum = -0.25;
  var hex = String('#' + Math.random().toString(16).slice(2, 8).toUpperCase()).replace(
    /[^0-9a-f]/gi,
    ''
  );
  if (hex.length < 6) {
    hex = hex[0] + hex[0] + hex[1] + hex[1] + hex[2] + hex[2];
  }
  var rgb = '#',
    c,
    i;
  for (i = 0; i < 3; i++) {
    c = parseInt(hex.substr(i * 2, 2), 16);
    c = Math.round(Math.min(Math.max(0, c + c * lum), 255)).toString(16);
    rgb += ('00' + c).substr(c.length);
  }
  return rgb;
}
